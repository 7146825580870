<script lang="ts">
  import { Confetti } from 'svelte-confetti';

  export let colorArray: string[] | undefined = undefined;
  export let amount: number | undefined = 200;
  export let duration: number | undefined = 5000;
  export let fallDistance: string | undefined = '100vh';
  export let size: number | undefined = 10;
</script>

<div
  class="fixed -top-10 -left-0 h-screen w-screen flex justify-center overflow-hidden pointer-events-none"
>
  <Confetti
    x={[-5, 5]}
    y={[0, 0.1]}
    delay={[1000, 2000]}
    infinite
    size={size ?? size}
    duration={duration ?? duration}
    amount={amount ?? amount}
    colorArray={colorArray ?? colorArray}
    fallDistance={fallDistance ?? fallDistance}
  />
</div>
